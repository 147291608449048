<template>
  <!-- <div class="show-edit-profile"> -->
  <div>
    <b-col md="12" v-if="profileData">
      <b-card-group deck>
        <b-card no-body header="My Account" class="heading-myaccount">
          <b-list-group flush>
            <router-link class="list-group-item" button to="/my-order"
              >My Orders</router-link
            >

            <router-link class="list-group-item" to="/WishList"
              >WishList</router-link
            >
            <router-link class="list-group-item" to="/view-address"
              >View Address</router-link
            >
            <!-- <b-list
              class="list-group-item"
              @click="$bvModal.show('bv-modal-example')"
            >Change Password</b-list>
            <b-modal centered id="bv-modal-example" hide-footer>
              <template #modal-title class="text-center">Change Password</template>
              <div class="d-block text-center">
                <v-text-field
                  v-model="oldPassword"
                  type="password"
                  class="textsize"
                  label="Old Password"
                ></v-text-field>
                <v-text-field
                  v-model="newPassword"
                  type="password"
                  class="textsize"
                  label="New Password"
                ></v-text-field>
                <v-text-field
                  v-model="confirmNewPassword"
                  type="password"
                  class="textsize"
                  label="Confirm Password"
                ></v-text-field>
              </div>
              <button
                type="button"
                class="btn btn-primary btn-lg center-btn"
                @click="onClickChangePassword"
              >
                <span class="button-text">Change Password</span>
              </button>
            </b-modal> -->

            <!-- <a class="list-group-item" @click="shareReferalLink()"
              >Refer a friend</a
            > -->
            <router-link class="list-group-item" to="/faq"
              >Frequently Asked Question</router-link
            >
            <router-link class="list-group-item" to="/privacy-policy"
              >Privacy Policy</router-link
            >
            <a class="list-group-item" @click="logout">Logout</a>
          </b-list-group>
        </b-card>
      </b-card-group>
    </b-col>
    <b-col md="12" v-else>
      <div class="text-center login-signup-myaccout">
        <h4>Sign in for the better experience</h4>
        <button
          class="btn btn-primary mb-4"
          size="lg"
          @click="$router.push('/login')"
        >
          Login / Signup
        </button>
      </div>
      <b-card-group deck>
        <b-card no-body header class="heading-myaccount">
          <b-list-group flush>
            <a class="list-group-item" @click="shareReferalLink()"
              >Refer a friend</a
            >
            <!-- <router-link class="list-group-item" to="/faq"
            >Frequently Asked Question</router-link
            >-->
            <router-link class="list-group-item" to="/terms-and-conditions"
              >Terms and Conditions</router-link
            >
            <router-link class="list-group-item" to="/privacy-policy"
              >Privacy Policy</router-link
            >
          </b-list-group>
        </b-card>
      </b-card-group>
    </b-col>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "my-account-detail",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      profileData: localStorage.getItem("eparisheva:userInfo") !== null,
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      currentPosition: (state) => state.location.currentPosition,
    }),
  },

  methods: {
    async shareReferalLink() {
      let payload = { code: this.userInfo?.customerNo || "" };
      let referrelLink = await this.$store.dispatch(
        "profile/createReferrel",
        payload
      );
      window.open("https://api.whatsapp.com/send?text=" + referrelLink);
    },

    async onClickChangePassword() {
      if (!this.oldPassword || !this.newPassword || !this.confirmNewPassword) {
        return this.$swal({
          title: "Error!",
          text: "please fill in all the details first.",
          type: "error",
        });
      }

      if (this.newPassword != this.confirmNewPassword) {
        return this.$swal({
          title: "Error!",
          text: "Passwords don't match",
          type: "error",
        });
      }

      let payload = {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword,
      };

      await this.$store
        .dispatch("profile/handleResetPassword", payload)
        .then(() => {
          this.oldPassword = "";
          this.newPassword = "";
          this.confirmNewPassword = "";
          this.$swal({
            text: "Password Changed Successfully",
            type: "success",
            title: "Success",
            timer: 1100,
          });
          this.$bvModal.hide("bv-modal-examples");
        })
        .catch((error) => {
          this.$swal({
            text: error.message,
            type: "error",
            title: "error",
            timer: 1100,
          });
        });
    },
    logout() {
      //   this.$store.dispatch("auth/resetUser");
      this.$bvModal
        .msgBoxConfirm("Do you want to logout from Eparisheva?", {
          title: "Please Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.commit("resetState");
            localStorage.removeItem("eparisheva:access_token");
            localStorage.removeItem("eparisheva:userInfo");

            this.$swal({
              text: "See you soon! Have a Good Day.",
              type: "success",
              title: "Success",
              timer: 1100,
            });
            this.$router.push("/login");
          }
        });
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/getCustomerData", this.userInfo._id);
  },
};
</script>

<style scoped>
.list-group-item {
  color: #495057;
}

.list-group-item:hover {
  background: #f8f9fa;
  text-decoration: none;
  color: #495057;
  cursor: pointer;
}

.card-header {
  font-weight: bold;
}

.textsize {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 400px;
}

.center-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.login-signup-myaccout {
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.login-signup-myaccout h4 {
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>
