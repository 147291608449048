var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.profileData ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    staticClass: "heading-myaccount",
    attrs: {
      "no-body": "",
      "header": "My Account"
    }
  }, [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "button": "",
      "to": "/my-order"
    }
  }, [_vm._v("My Orders")]), _c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "to": "/WishList"
    }
  }, [_vm._v("WishList")]), _c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "to": "/view-address"
    }
  }, [_vm._v("View Address")]), _c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "to": "/faq"
    }
  }, [_vm._v("Frequently Asked Question")]), _c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Privacy Policy")]), _c('a', {
    staticClass: "list-group-item",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Logout")])], 1)], 1)], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center login-signup-myaccout"
  }, [_c('h4', [_vm._v("Sign in for the better experience")]), _c('button', {
    staticClass: "btn btn-primary mb-4",
    attrs: {
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/login');
      }
    }
  }, [_vm._v(" Login / Signup ")])]), _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    staticClass: "heading-myaccount",
    attrs: {
      "no-body": "",
      "header": ""
    }
  }, [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('a', {
    staticClass: "list-group-item",
    on: {
      "click": function click($event) {
        return _vm.shareReferalLink();
      }
    }
  }, [_vm._v("Refer a friend")]), _c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "to": "/terms-and-conditions"
    }
  }, [_vm._v("Terms and Conditions")]), _c('router-link', {
    staticClass: "list-group-item",
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Privacy Policy")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }